import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { preparePreload } from '../Services';
import './PlotMap.css';

import { CRS, LatLngBounds } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {
  ImageOverlay,
  MapContainer,
  Pane,
  Polygon,
  ZoomControl,
} from 'react-leaflet';
import {
  getCoordinates,
  matches,
  parseHotspotOpacitySettings,
} from '../Services/Entities/Hotspot';
import { getLayerHotspotColor } from '../Services/Entities/Layer';

export default function PlotMap({
  layers,
  plots,
  activeLayer,
  activeHotspot,
  onHotspotClick,
  filteredPlots,
  statuses,
  hotspots,
}) {
  const backgroundImageRef = useRef(null);
  const imageOverlayRef = useRef(null);
  const mapRef = useRef(null);

  const opacitySettings = parseHotspotOpacitySettings(activeLayer);

  preparePreload();

  const renderHotspot = (hotspot, index) => {
    const defaultFill = {
      fill: true,
      fillColor: '#fff',
      fillOpacity: 0.2,
      color: '#fff',
      stroke: '#fff',
      weight: 3,
      opacity: 1,
      className: 'hotspot',
    };

    const coordinates = getCoordinates(hotspot);

    if (coordinates.length <= 0) return <></>;

    const fill = setHotspotFill(hotspot);
    const pathOptions = {
      ...defaultFill,
      ...fill,
    };

    const type = hotspot.entity_type.replace('App\\Models\\', '');
    const key = `${type}-${index}-${hotspot.entity_id}-${hotspot.layer_id}`;

    return (
      <Polygon
        key={key}
        positions={getCoordinates(hotspot)}
        eventHandlers={{
          click: () => onHotspotClick(hotspot),
        }}
        pathOptions={pathOptions}
        className={pathOptions.className}
      />
    );
  };

  /**
   * Set the fill of the hotspot based on the type of the hotspot.
   * @param {object} hotspot The hotspot object
   * @returns {object} The path options for the hotspot
   */
  const setHotspotFill = (hotspot) => {
    if (hotspot.entity_type === 'App\\Models\\Plot') {
      return setPlotHotspotFill(hotspot);
    }

    if (hotspot.entity_type === 'App\\Models\\Layer') {
      return setLayerHotspotFill(hotspot);
    }

    return {};
  };

  /**
   * The styling attributes for the plot hotspot.
   * @param {object} plot The plot to set the fill for.
   * @returns {object} The attributes for the plot hotspot.
   */
  const setPlotHotspotFill = (hotspot) => {
    const plot = plots[hotspot.entity_id];

    if (!plot) return {};

    let fillOpacity = opacitySettings.svg_opacity_no_filter;
    if (activeHotspot && activeHotspot?.id === plot.id) {
      fillOpacity = opacitySettings.svg_opacity_hover_active;
    } else if (matches(filteredPlots, plot)) {
      fillOpacity = opacitySettings.svg_opacity_filter;
    }

    let status;
    try {
      status = statuses[plot.status];
    } catch (error) {
      status = { color: '#fff' };
    }

    return {
      color: status.color,
      fillColor: status.color,
      fillOpacity: fillOpacity,
      className: 'hotspot plot',
    };
  };

  /**
   * Set the fill of the layer hotspot based on the status of the layer's plot hotspots.
   * @param {object} hotspot The layer's hotspot
   * @returns {object} The path options for the layer's hotspot
   */
  const setLayerHotspotFill = (hotspot) => {
    const layer = layers[hotspot.entity_id];

    const plotStatuses = getLayerHotspotColor(
      layer,
      layers,
      filteredPlots,
      hotspots
    );

    let layerStatus = null;
    if (plotStatuses.includes('te-koop')) {
      layerStatus = statuses['te-koop'];
    } else if (plotStatuses.includes('in-optie')) {
      layerStatus = statuses['in-optie'];
    } else if (plotStatuses.includes('gereserveerd')) {
      layerStatus = statuses['gereserveerd'];
    } else if (plotStatuses.includes('verkocht')) {
      layerStatus = statuses['verkocht'];
    } else if (plotStatuses.includes('voorbereiden-start-verkoop')) {
      layerStatus = statuses['voorbereiden-start-verkoop'];
    } else if (plotStatuses.includes('in-voorbereiding')) {
      layerStatus = statuses['in-voorbereiding'];
    }

    if (!layerStatus) return {};

    return {
      color: layerStatus.color,
      fillColor: layerStatus.color,
      className: 'hotspot layer',
      fillOpacity: opacitySettings.svg_opacity_filter,
    };
  };

  /**
   * Preloads the background images of all layers that are children of the current active layer.
   * This reduces delay between switching layers and being able to see the background image.
   * @param {object} currentLayer The current active layer
   * @param {object} allLayers A list of all layers in the project
   */
  const lazyLoadLayerBackgrounds = (currentLayer, allLayers) => {
    Object.values(allLayers)
      .filter((layer) => {
        const current = layer.id === currentLayer.id;
        const parent = layer.id === currentLayer.parent_id;
        const child = layer.parent_id === currentLayer.id;
        const sibling = layer.parent_id === currentLayer.parent_id;
        return !current && (parent || sibling || child);
      })
      .map((layer) => {
        const img = new Image();
        img.src = layer.background.url;
      });
  };

  /**
   * Creates a LatLngBounds object based on the background image.
   * @param {object} background the API object of the background image
   * @param {number} scale the scale of the bounds, default 0.1
   * @returns {object} the LatLngBounds representation of the background image
   */
  const getBounds = (background, scale = 0.09) => {
    return new LatLngBounds([
      [
        background.width / 2 - (background.height / 2) * scale,
        background.height / 2 - (background.width / 2) * scale,
      ],
      [
        background.width / 2 + (background.height / 2) * scale,
        background.height / 2 + (background.width / 2) * scale,
      ],
    ]);
  };

  const handleResize = useCallback(() => {
    if (mapRef.current === null) return;
    const bounds = getBounds(activeLayer.background);

    mapRef.current
      .setMaxBounds(bounds.pad(0.125))
      .fitBounds(bounds, {
        animate: false,
      })
      .invalidateSize(false);
  }, [mapRef, activeLayer.id]);

  useEffect(() => {
    lazyLoadLayerBackgrounds(activeLayer, layers);

    if (imageOverlayRef.current === null || mapRef.current === null) return;
    const bounds = getBounds(activeLayer.background);

    imageOverlayRef.current
      .setUrl(activeLayer.background.url)
      .setBounds(bounds);

    backgroundImageRef.current
      .setUrl(activeLayer.background.url)
      .setBounds(bounds);

    handleResize();
  }, [activeLayer.id]);

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize, false);
    };
  }, [activeLayer.id]);

  const bounds = getBounds(activeLayer.background);

  const hotspotOpacitySettings = parseHotspotOpacitySettings(activeLayer);

  return (
    <Box
      className="relative w-full overflow-hidden rounded map"
      w="100%"
      sx={{
        '--hotspot-default': hotspotOpacitySettings.svg_opacity_no_filter,
        '--hotspot-matches': hotspotOpacitySettings.svg_opacity_filter,
        '--hotspot-active': hotspotOpacitySettings.svg_opacity_hover_active,
      }}
    >
      <Box pos={'relative'} zIndex={1}>
        <MapContainer
          attributionControl={false}
          zoomControl={false}
          crs={CRS.Simple}
          style={{ width: '100vw', height: 'calc(100vh - 4rem)' }} // The offset matches the height of the map controls
          padding={0}
          zoom={1}
          zoomDelta={0.5} // Controls zoom increments used by zoom controls
          zoomSnap={0} // Controls increments used by fitBounds
          scrollWheelZoom={false}
          doubleClickZoom={false}
          center={bounds.getCenter()}
          maxBoundsViscosity={1}
          maxBounds={bounds.pad(0.125)}
          ref={mapRef}
          whenReady={(map) => {
            map.target.setMaxBounds(bounds.pad(0.125)).fitBounds(bounds, {
              animate: false,
            });
          }}
        >
          <ZoomControl position="bottomright" />

          <Pane pane="overlayPane">
            <ImageOverlay
              ref={backgroundImageRef}
              key={`image-background-${activeLayer.background.name}`}
              url={activeLayer.background.url}
              bounds={bounds.pad(0.125)}
              interactive={false}
              className={'map-background'}
            />

            <ImageOverlay
              ref={imageOverlayRef}
              key={`image-overlay-${activeLayer.background.name}`}
              url={activeLayer.background.url}
              bounds={bounds}
              interactive={false}
              eventHandlers={{
                load: () => {
                  lazyLoadLayerBackgrounds(activeLayer, layers);
                },
              }}
            />
          </Pane>

          <Pane pane="markerPane">
            {hotspots
              .filter((hotspot) => hotspot.layer_id === activeLayer.id)
              .map((hotspot, index) => renderHotspot(hotspot, index))}
          </Pane>
        </MapContainer>
      </Box>
    </Box>
  );
}

PlotMap.propTypes = {
  activeHotspot: PropTypes.object,
  activeLayer: PropTypes.object.isRequired,
  filteredPlots: PropTypes.array.isRequired,
  onHotspotClick: PropTypes.func.isRequired,
  layers: PropTypes.object.isRequired,
  plots: PropTypes.object.isRequired,
  hotspots: PropTypes.array.isRequired,
  statuses: PropTypes.object.isRequired,
};

/**
 * Order all plots based on the sorting type.
 * @param {Array} plots All plots to sort.
 * @param {string} type The type of sorting to use.
 * @param {Array} statuses The project's plots statuses.
 * @returns {Array} A list of sorted plots.
 */
function sortPlots(plots, type, statuses) {
  const defaultFilteredPlots = plots.sort((a, b) => {
    const numberComparison = ascending(
      a.number.toString(),
      b.number.toString()
    );
    if (numberComparison !== 0) return numberComparison;
  });
  return defaultFilteredPlots.sort((a, b) => {
    if (type === 'number')
      return ascending(a.number.toString(), b.number.toString());
    else if (type === 'numberDESC')
      return descending(a.number.toString(), b.number.toString());
    else if (type === 'type') return ascending(a.type, b.type);
    else if (type === 'costASC') return sortPlotsByAscCost(a, b);
    else if (type === 'costDESC') return sortPlotsByDescCost(a, b);
    else if (type === 'livingSurfaceASC')
      return ascending(
        a.living_surface.toString(),
        b.living_surface.toString()
      );
    else if (type === 'livingSurfaceDESC')
      return descending(
        a.living_surface.toString(),
        b.living_surface.toString()
      );
    else if (type === 'plotSurfaceASC')
      return ascending(a.plot_surface.toString(), b.plot_surface.toString());
    else if (type === 'plotSurfaceDESC')
      return descending(a.plot_surface.toString(), b.plot_surface.toString());
    else if (type === 'status') {
      if (a.status.toLowerCase() === 'verkocht') return 1;
      if (b.status.toLowerCase() === 'verkocht') return -1;

      return ascending(
        statuses[a.status].order.toString(),
        statuses[b.status].order.toString()
      );
    }

    return null;
  });
}

/**
 * ASCENDING: Puts the lowest value on top.
 * @param {object} a Plot A
 * @param {object} b Plot B
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare}
 * @returns {number} 1, -1 or 0
 */
function ascending(a, b) {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
}

/**
 * DESCENDING: Puts the highest value on top.
 * @param {object} a Plot A
 * @param {object} b Plot B
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare}
 * @returns {number} 1, -1 or 0
 */
function descending(a, b) {
  return b.localeCompare(a, undefined, { numeric: true, sensitivity: 'base' });
}

/**
 * Puts the lowest value on top.
 * Checks for plots with the status of 'Verkocht' and sorts them at the bottom of the list.
 * Continues to sort as usual.
 * @param {object} a Plot A
 * @param {object} b Plot B
 * @returns {number} 1, -1 or 0
 */
function sortPlotsByAscCost(a, b) {
  if (
    a.status === 'verkocht' ||
    a.status === 'voorbereiden-start-verkoop' ||
    a.status === 'in-voorbereiding'
  ) {
    return 1;
  } else if (
    b.status === 'verkocht' ||
    b.status === 'voorbereiden-start-verkoop' ||
    b.status === 'in-voorbereiding'
  ) {
    return -1;
  }

  return a.price.toString().localeCompare(b.price.toString(), undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}

/**
 * Puts the highest value on top.
 * Checks for plots with the status of 'Verkocht' and sorts them at the bottom of the list.
 * Continues to sort as usual.
 * @param {object} a Plot A
 * @param {object} b Plot B
 * @returns {number} 1, -1 or 0
 */
function sortPlotsByDescCost(a, b) {
  if (
    a.status === 'verkocht' ||
    a.status === 'voorbereiden-start-verkoop' ||
    a.status === 'in-voorbereiding'
  ) {
    return 1;
  } else if (
    b.status === 'verkocht' ||
    b.status === 'voorbereiden-start-verkoop' ||
    b.status === 'in-voorbereiding'
  ) {
    return -1;
  }

  return b.price.toString().localeCompare(a.price.toString(), undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}

export { sortPlots };
